<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-row>
        <b-col class='d-flex align-items-center'>
          <BackButton></BackButton>
          <h5 class='ml-2'>Generic Importer Settings for client
            <b-link v-if='client' :to='`/onboarding/client/edit/${client.id}`'>{{ client.display_name }}</b-link>
          </h5>
        </b-col>
      </b-row>
      <b-row class='mt-4'>
        <b-col>
          <b-card class='mb-4'>
            <template #header>
              <h6 class='mb-0'>List of configurations by File Type</h6>
            </template>
            <b-table :items="availableFileTypes" :fields="fields" striped responsive>
              <template #cell(actions)="row">
                <b-button size="sm" @click="addNewConfiguration(row.item.id)" class="mr-2 btn-success">
                  Add Configuration
                </b-button>
                <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-info">
                  {{ row.detailsShowing ? 'Hide' : 'Show' }} Configurations
                </b-button>
              </template>
              <template #cell(number_of_configured)="row">
                {{ row.item.configurations.length }}
              </template>
              <template #row-details="row">
                <b-card class="inner-table" v-if="row.item.configurations.length > 0">
                  <b-row class="mb-auto">
                    <b-table :items="row.item.configurations" :fields="['filepath', 'actions']" caption-top>
                      <template #table-caption>Configured providers:</template>
                      <template #cell(actions)="innerRow">
                        <b-button size="sm" @click="viewConfiguration(row.item.id, innerRow.item.id)" class="mr-2 btn-info">View</b-button>
                        <b-button size="sm" @click="modifyConfiguration(row.item.id, innerRow.item.id)" class="mr-2 btn-success">Modify</b-button>
                        <b-button
                          size="sm"
                          @click="openDeleteConfigurationModal(innerRow.item, row.index, row.item, $event.target)"
                          class="mr-2 btn-danger">
                          Delete
                        </b-button>
                      </template>
                    </b-table>
                  </b-row>
                </b-card>
                <b-card class="inner-table" v-else>
                  <b-row class="mb-auto">
                    <p>No available configurations</p>
                  </b-row>
                </b-card>
              </template>
            </b-table>
            <div class='col-12 d-flex justify-content-center'>
              <b-pagination :disabled='loading'
                            @change='changePage'
                            v-model='currentPage'
                            :total-rows='total'
                            :per-page='itemsPerPage'
                            size='md'>
              </b-pagination>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal
      :id="deleteConfigurationModal.id"
      title="Are you sure you want to delete this configuration?"
      ok-only
      ok-variant="secondary"
      ok-title="Close">
      <b-card>
        <b-card-text>
          <small>Filetype: <b>{{ deleteConfigurationModal.fileTypeName }}</b></small>
          <br>
          <small>Filepath: <b>{{ deleteConfigurationModal.filepath }}</b></small>
        </b-card-text>
      </b-card>
      <b-button
        class="mt-3"
        variant="outline-danger"
        @click="deleteConfiguration(deleteConfigurationModal.details)"
        block>
        Delete Configuration
      </b-button>
    </b-modal>
  </b-container>
</template>

<script>

import BackButton from '@/components/BackButton.vue';

export default {
  components: { BackButton },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      loading: true,
      client: null,
      fields: [
        {
          key: 'name',
          label: 'File Type Name',
          thStyle: { width: '30%' },
        },
        {
          key: 'number_of_configured',
          label: 'Number of Configured',
          thStyle: { width: '40%' },
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      uniqueIdConfigs: [],
      availableFileTypes: [],
      configuredFileTypes: [],
      deleteConfigurationModal: {
        id: 'info-modal',
        title: '',
        fileTypeName: '',
        filepath: '',
        details: {
          provider_id: null,
          file_type_id: null,
        },
      },
    };
  },
  async beforeMount() {
    this.offset = 0;
    this.limit = this.itemsPerPage;
    this.currentPage = 1;
    await this.loadPage();
  },
  methods: {
    async loadPage() {
      this.loading = true;
      const clients = await this.$store.dispatch('Core/fetchClients');
      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
      await this.getClientConfigs(this.$route.params.clientId);
      await this.getAvailableFileTypes();
      this.loading = false;
    },
    async getClientConfigs(clientId) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getClientImporters', { clientId });
      this.uniqueIdConfigs = res?.data?.unique_id_fields || [];
      this.configuredFileTypes = res?.data?.configurations || [];
    },
    async getAvailableFileTypes() {
      try {
        this.loading = true;
        const { data } = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporters', {
          offset: this.offset,
          limit: this.limit,
        });
        this.availableFileTypes = data.data.map(i => ({
          id: i.id,
          name: i.name,
          configurations: this.getConfigurationsByType(i.id),
        }));
        this.total = data.count;
      } catch (e) {
        this.$noty.error(e.message);
      } finally {
        this.loading = false;
      }
    },
    getConfigurationsByType(fileTypeId) {
      return this.configuredFileTypes.filter(i => Number(i.file_type_id) === Number(fileTypeId)).map(i => ({
        filepath: i.filepath,
        id: i.id,
      }));
    },
    openDeleteConfigurationModal(item, index, fileType, button) {
      this.deleteConfigurationModal.title = `Row index: ${index}`;
      this.deleteConfigurationModal.filepath = item.filepath;
      this.deleteConfigurationModal.fileTypeName = fileType.name;
      this.deleteConfigurationModal.details.provider_id = item.id;
      this.deleteConfigurationModal.details.file_type_id = fileType.id;
      this.$root.$emit('bv::show::modal', this.deleteConfigurationModal.id, button);
    },
    resetInfoModal() {
      this.deleteConfigurationModal.title = null;
      this.deleteConfigurationModal.filepath = null;
      this.deleteConfigurationModal.fileTypeName = null;
      this.deleteConfigurationModal.details.provider_id = null;
      this.deleteConfigurationModal.details.file_type_id = null;
    },
    addNewConfiguration(id) {
      return this.$router.push({
        name: 'AddNewGenericFileTypeConfiguration',
        params: {
          clientId: Number(this.$route.params.clientId),
          fileTypeId: id,
        },
      });
    },
    viewConfiguration(fileTypeId, providerId) {
      return this.$router.push({
        name: 'ViewGenericFileTypeConfiguration',
        params: {
          clientId: Number(this.$route.params.clientId),
          fileTypeId,
          providerId,
        },
      });
    },
    modifyConfiguration(fileTypeId, providerId) {
      return this.$router.push({
        name: 'UpdateGenericFileTypeConfiguration',
        params: {
          clientId: Number(this.$route.params.clientId),
          fileTypeId,
          providerId,
        },
      });
    },
    async deleteConfiguration(details) {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/GenericImporter/deleteGenericImportClientConfiguration', {
          importerId: details.file_type_id,
          providerId: details.provider_id,
          onboardingClientId: Number(this.$route.params.clientId),
        });

        this.loading = false;
        this.$root.$emit('bv::hide::modal', this.deleteConfigurationModal.id);
        this.$noty.success('Configuration deleted with success');
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.resetInfoModal();
        await this.loadPage();
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.offset = (this.currentPage - 1) * this.itemsPerPage;
      this.limit = this.itemsPerPage;
      this.getAvailableFileTypes();
    },
  },
};
</script>

<style scoped lang="scss">
  .inner-table > .card-body {
    padding-top: inherit;
    padding-bottom: inherit;
  }
</style>
